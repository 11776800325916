import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { FREE_SUBSCRIPTION_SYMBOLS_LIMIT } from '@app/common';
import Head from 'next/head';
import Link from 'next/link';
import { useRootStore } from '../../providers/root-store-provider';
import { withAuth } from '../../models/functions/with-auth';
// import usePwa from '../../hooks/usePwa';
// import { PwaDialog } from '../../components/app/dialogs/pwa/pwa-dialog';
import { DashboardCards } from '../../blocks/app/dashboard/cards/dashboard-cards';
import { DashboardCharts } from '../../blocks/app/dashboard/charts/dashboard-charts';
import { DashboardProfitChart } from '../../blocks/app/dashboard/profit-chart/dashboard-profit-chart';
import { DashboardAllocations } from '../../blocks/app/dashboard/allocations/dashboard-allocations';
import { Layout } from '../../blocks/app/layout/layout';
import { DashboardNoData } from '../../blocks/app/dashboard/no-data/dashboard-no-data';
import { PageHeadline } from '../../components/app/page-headline/page-headline';
import { LimitedTradesForFreeUser } from '../../components/app/limited-trades-for-free-user/limited-trades-for-free-user';
import { LookupKeyEnum } from '../../defs/api';
import { Button } from '../../components/app/form/button/button';
import { IconHelp } from '../../components/app/icons/help';
import { Alert } from '../../components/app/alert/alert';
import { Stack } from '../../components/app/stack/stack';
import { routes } from '../../config/routes';

const IndexPage: React.FC = () => {
    const {
        portfolioValueCalculationInfoDialogStore,
        statisticStore,
        portfolioStore,
        authStore,
        premiumDialogStore,
        stripeStore,
        userStore,
    } = useRootStore();
    const router = useRouter();
    const { t } = useTranslation();

    useEffect(() => {
        if (!portfolioStore.selectedPortfolio.isRecalculating) {
            statisticStore.fetchIndexData();
            statisticStore.profitStore.fetchProfitCharts();
            premiumDialogStore.openAfterLoginForFreeSubscription();
        }

        return () => statisticStore.startLoading();
    }, []);

    useEffect(() => {
        if (router.query.openStripeSettings === 'true') {
            stripeStore.createPortalSession();
        }
        if (router.query.getYearlySubscription === 'true') {
            stripeStore.createCheckoutSession(LookupKeyEnum.PremiumYearlyCzk202411);
        }
        if (router.query.getMonthlySubscription === 'true') {
            stripeStore.createCheckoutSession(LookupKeyEnum.PremiumMonthlyCzk202411);
        }
        if (router.query.portfolioValueCalculationInfo === 'true') {
            portfolioValueCalculationInfoDialogStore.open();
        }
    }, [router.query]);

    return (
        <Layout>
            <Head>
                <title>
                    {t('pages.app.dashboard.meta.title')}
                    {portfolioStore.selectedPortfolio.name}
                    {t('meta.title.suffix')}
                </title>
            </Head>

            {statisticStore.ratiosStore.soldMoreThanPurchasedSymbols?.length > 0 &&
            userStore.settings?.showSymbolAmountWarning ? (
                <Alert type={'error'} icon>
                    <Stack alignItems="center" justifyContent="space-between" fullWidth>
                        {t('pages.app.dashboard.symbolsSoldMoreThanPurchased')}
                        {statisticStore.ratiosStore.soldMoreThanPurchasedSymbols.map((symbol) => (
                            <Link key={symbol} href={`${routes.app.trades}?filter=${symbol}`}>
                                {symbol}
                            </Link>
                        ))}
                    </Stack>
                </Alert>
            ) : null}

            {/* {showInstallDialog ? <PwaDialog onConfirm={() => handleInstall()} /> : null} */}
            <PageHeadline
                title={t('pages.index.title')}
                mobileBreakUnder="xl"
                action={
                    <Button
                        onClick={() => portfolioValueCalculationInfoDialogStore.open()}
                        variant={'positive'}
                        icon={<IconHelp />}
                        iconGap={'xsmall'}
                    >
                        {t('pages.index.openPortfolioValueCalculationInfoDialog')}
                    </Button>
                }
            />
            {portfolioStore.selectedPortfolio.numberOfTrades === 0 ? (
                <DashboardNoData />
            ) : (
                <>
                    {authStore.userHasFreeTariff &&
                    portfolioStore.selectedPortfolio.numberOfAssets >= FREE_SUBSCRIPTION_SYMBOLS_LIMIT ? (
                        <LimitedTradesForFreeUser />
                    ) : null}

                    <DashboardCards />

                    <DashboardCharts />

                    <DashboardProfitChart />

                    <DashboardAllocations />
                </>
            )}
        </Layout>
    );
};

export default withAuth(IndexPage);
